import React, { useCallback, useEffect } from 'react'
import dynamic from 'next/dynamic'

import * as Icon from 'components/DesignSystemIcons'
import { MastheadGroup, MastheadIcon, MastheadTitle } from 'components/Masthead'
import Menu from 'components/pages/explore/stores/StoreMenu'
import { StandardPage } from 'components/Layout'

import { styled } from 'styles/stitches.config'
import { Box, IconButton } from 'design-system'

import { browserBack } from 'utils/browserBack'
import { isEmpty } from 'lodash'
import {
  storeItemOptionsState,
  storeItemRemovedOptionsState,
} from 'config/states'
import { useResetRecoilState } from 'recoil'

import type { StoreMenuResponse } from 'types/api'
import FavoriteButton from 'components/FavoriteButton'
import { CSS } from '@stitches/react'
import { StoreActions } from 'components/pages/explore/stores/storeId/StoreActions'
import { useSWR } from 'hooks/useSWR'
import { useRouter } from 'next/router'
import { logError } from 'utils/honeybadger'
import { StoreDetailsOnMap } from 'types/api/store'

export type StoreIdScreenProps = { balance: number | null }

const StoreTags = dynamic(
  () => import('components/pages/explore/stores/StoreTags'),
  {
    ssr: false,
  }
)

const BannerContainer = styled('div', {
  position: 'relative',
})

const Banner = styled('div', {
  position: 'relative',
  width: '100%',
  aspectRatio: 2.5,
  borderBottomLeftRadius: '40% 90%',
  overflow: 'hidden',
  maxHeight: 200,
})

const Img = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
})

const LogoWrapper = styled('div', {
  position: 'absolute',
  width: 92,
  height: 92,
  bottom: 0,
  left: '$xxxs',
})

const Logo = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  borderRadius: '50%',
  boxShadow: '$generalDrop',
  backgroundColor: '$grey200',
  border: '4px solid $grey50',
})

const favoriteButtonCSS: CSS = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  border: '3px solid $grey50',
  borderRadius: '50%',
  height: 30,
  width: 30,
  padding: '$xxxs',
}

const StoreId = ({ balance }: StoreIdScreenProps) => {
  const router = useRouter()

  const { storeId } = router.query as { storeId: string }

  const { data } = useSWR('getStoreDetailsById', { storeId })

  const {
    close,
    categories,
    online,
    open,
    photo,
    logo,
    storeName,
    wait,
    featuredItemsByCategoryIds,
    companyId,
    favorite,
    popularItems,
    isOpen,
  } = data?.data as StoreMenuResponse

  const store = { close, online, open, wait, isOpen }

  const resetOptions = useResetRecoilState(storeItemOptionsState)
  const resetRemovedOptions = useResetRecoilState(storeItemRemovedOptionsState)

  useEffect(() => {
    return () => {
      resetOptions()
      resetRemovedOptions()
    }
  }, [resetOptions, resetRemovedOptions])

  const HeaderComponent = useCallback(
    () => (
      <MastheadGroup>
        <MastheadIcon
          aria-label="back-button"
          onClick={browserBack}
          type="button"
        >
          <Icon.ChevronLeft />
        </MastheadIcon>
        <MastheadTitle center small>
          {storeName} - Menu
        </MastheadTitle>
        <MastheadIcon as="span">
          <Icon.Blank />
        </MastheadIcon>
      </MastheadGroup>
    ),
    [storeName]
  )

  const handleInfoOnClick = () => {
    router
      .push({
        pathname: '/explore/stores/[storeId]/info',
        query: {
          storeId,
        },
      })
      .catch(logError)
  }

  return (
    <StandardPage headerComponent={HeaderComponent} headerVariant="standard">
      <BannerContainer>
        <Banner>
          <Img alt="" src={photo || '/images/coffee-shop-default.jpg'} />
        </Banner>
        <LogoWrapper>
          <Logo>{logo && <Img alt="" src={logo} />}</Logo>
          <FavoriteButton
            css={favoriteButtonCSS}
            storeId={storeId}
            like={favorite}
            small
          />
        </LogoWrapper>
      </BannerContainer>
      <Box
        flex
        justifyContent="between"
        alignItems="center"
        css={{ padding: '$xl $s $s' }}
      >
        <StoreTags store={store as StoreDetailsOnMap} />
        <IconButton
          onClick={handleInfoOnClick}
          noShadow
          size="small"
          css={{
            backgroundColor: 'transparent',
            height: 'auto',
          }}
          aria-label="Store Info"
        >
          <Icon.Info size={16} />
        </IconButton>
      </Box>
      <StoreActions companyId={companyId} storeId={storeId} balance={balance} />

      {!isEmpty(categories) && (
        <Menu
          featuredItemsByCategoryIds={featuredItemsByCategoryIds}
          categories={categories}
          storeId={storeId}
          popularItems={popularItems}
          hideFeaturedWidget
          hideRecommendedWidget
        />
      )}
    </StandardPage>
  )
}

export default StoreId
